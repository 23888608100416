/*
 *
 * CheckoutPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CheckoutPage/DEFAULT_ACTION',
  GO_TO_STEP = 'app/CheckoutPage/GO_TO_STEP',
  SET_CHECKOUT = 'app/CheckoutPage/SET_CHECKOUT',
  SET_CHECKOUT_STATUS = 'app/CheckoutPage/SET_CHECKOUT_STATUS',
  UPDATE_DELIVERY_DETAILS = 'app/CheckoutPage/UPDATE_DELIVERY_DETAILS',
  CREATE_ORDER_DRAFT = 'app/CheckoutPage/CREATE_ORDER_DRAFT',
  CREATE_ORDER_DRAFT_REQUEST = 'app/CheckoutPage/CREATE_ORDER_DRAFT_REQUEST',
  CREATE_ORDER_DRAFT_SUCCESS = 'app/CheckoutPage/CREATE_ORDER_DRAFT_SUCCESS',
  CREATE_ORDER_DRAFT_FAILURE = 'app/CheckoutPage/CREATE_ORDER_DRAFT_FAILURE',
  CHECKOUT_CART = 'app/CheckoutPage/CHECKOUT_CART',
  CHECKOUT_CART_REQUEST = 'app/CheckoutPage/CHECKOUT_CART_REQUEST',
  CHECKOUT_CART_SUCCESS = 'app/CheckoutPage/CHECKOUT_CART_SUCCESS',
  CHECKOUT_CART_FAILURE = 'app/CheckoutPage/CHECKOUT_CART_FAILURE',
  TRACK_ORDERS_COMPLETED = 'app/CheckoutPage/TRACK_ORDERS_COMPLETED',
  SET_OPEN_CONFIRM_SAME_DAY_DELIVERY_MODAL = 'app/CheckoutPage/SET_OPEN_CONFIRM_SAME_DAY_DELIVERY_MODAL',
  SOMETHING_CHANGED = 'app/CheckoutPage/SOMETHING_CHANGED',
}

export default ActionTypes;
